/* eslint-disable */
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

import { DefaultLayout } from '../../components/layout';
import { CaseBlock, Facts, Stage } from '../../components/molecules';
import { Button } from '../../components/atoms';

import './deepgpt.scss';

const deepgptQuery = graphql`
  {
    allStagesJson(filter: { siteTitle: { eq: "DeepGPT" } }) {
      edges {
        node {
          siteTitle
          siteDescription
          title
          contentBlocks {
            id
            value
          }
          imageSrc {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 800
                quality: 92
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
          imageAlt
        }
      }
    }
  }
`;

const DeepGPTCase = () => (
  <StaticQuery
    query={deepgptQuery}
    render={({ allStagesJson }) => {
      // console.log("🔍 Debugging GraphQL Data:", { allStagesJson });

      if (!allStagesJson.edges.length) {
        console.error("❌ Error: No stage data found!");
        return null;
      }

      const stageNode = allStagesJson.edges[0].node;
      const { siteTitle, siteDescription, imageSrc, imageAlt, title, contentBlocks } = stageNode;

      // ✅ Get Stage Image (Handled like in `projekte.js`)
      const stageImage = imageSrc?.childImageSharp
        ? getImage(imageSrc.childImageSharp.gatsbyImageData)
        : imageSrc?.publicURL || null;

      // console.log("🔍 Debugging Stage Image:", stageImage);

      return (
        <DefaultLayout siteTitle={siteTitle} siteDescription={siteDescription}>
          {/* ✅ Main Stage Section */}
          <Stage
            modifiers={['left-highlighted', 'gradient']}
            image={{
              gatsbyImageData: typeof stageImage === "object" ? stageImage : null,
              src: typeof stageImage === "string" ? stageImage : null,
              alt: imageAlt || "DeepGPT AI Solution",
            }}
            title={<h1 dangerouslySetInnerHTML={{ __html: title }} />}
          >
            <div>
              {contentBlocks.map(({ id, value }) => (
                <p key={id} dangerouslySetInnerHTML={{ __html: value }} />
              ))}
            </div>
            <div className="learn-more__button">
              <Button url="/kontakt" text="Jetzt Kontakt aufnehmen" isPrimary />
            </div>
          </Stage>

          {/* CASE BLOCK 1 - Datenschutz & Performance */}
          <div className="case-container">
            <CaseBlock
              title="DeepGPT – Maximale Datensicherheit"
              subtitle="Schweizer Hosting oder On-Premises"
              graphic={null}
            >
              <p>
                DeepGPT ist unsere leistungsfähige, generative KI-Lösung, die speziell für sensible
                Daten konzipiert wurde. Ob im Schweizer Rechenzentrum oder direkt in Ihrer eigenen
                IT-Umgebung – Sie behalten stets die volle Kontrolle über Ihre Daten.
              </p>
              <p>
                Durch datenschutzkonforme Architektur und Hosting-Optionen erfüllen Sie alle gesetzlichen
                Vorgaben, beispielsweise gemäss revDSG. Ideal für Branchen mit erhöhten Anforderungen an
                Sicherheit und Vertraulichkeit, wie etwa Gesundheitswesen, Finanzsektor oder öffentliche
                Verwaltungen.
              </p>
            </CaseBlock>
          </div>

          {/* CASE BLOCK 2 - Einsatzmöglichkeiten */}
          <div className="case-container">
            <CaseBlock
              title="Vielseitige Einsatzmöglichkeiten"
              subtitle="Von Kundensupport bis HR"
              graphic={null}
            >
              <p>
                Nutzen Sie DeepGPT, um wiederkehrende Anfragen vollautomatisch zu beantworten und
                interne Prozesse zu beschleunigen. Ob Kundensupport, HR-Anliegen oder komplexe
                Dokumenten-Recherchen – unser System kann Ihre spezifischen Unternehmensdaten
                integrieren, ohne dass sensible Informationen das gesicherte Umfeld verlassen.
              </p>
              <p>
                Damit entlasten Sie Ihr Team und steigern gleichzeitig die Zufriedenheit Ihrer
                Kunden und Mitarbeitenden. DeepGPT passt sich nahtlos an Ihre Systeme an und
                unterstützt beliebig viele Workflows.
              </p>
            </CaseBlock>
          </div>

          {/* FACTS - Key Features */}
          <div className="facts-container">
            <Facts title="Wichtige Funktionen">
              <ul>
                <li>Hosting in Schweizer Datencentern oder On-Premises</li>
                <li>Konzipiert für sensible Daten: Healthcare, Finanzen, Behörden u. v. m.</li>
                <li>Beliebige Unternehmensdaten integrierbar (z. B. FAQs, HR-Dokumente)</li>
                <li>Mehrsprachige Dialoge für globalen Einsatz</li>
                <li>Nahtlose Anbindung an CRM, Ticketing und andere IT-Systeme</li>
                <li>Datenschutzkonform nach revDSG</li>
              </ul>
            </Facts>
          </div>
        </DefaultLayout>
      );
    }}
  />
);

export default DeepGPTCase;
